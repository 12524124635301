<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="item.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :dependentLinks="dependentLinks"
    disabledAllFields
    :disabledSaveButton="isEqual"
    @cancel="onCancel('Organizations')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'OrganizationEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Organizations',
      pageHeader: 'Просмотр организации',
      form: {},
    };
  },

  computed: {
    ...mapState(['item']),

    breadcrumbs() {
      return [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Организации',
          route: { name: 'Organizations' },
        },
        {
          text: `Просмотр организации ${this.form.shortName}`,
        },
      ];
    },

    fields() {
      return [
        {
          groups: [
            {
              groupTitle: 'Реквизиты',
              form: [
                {
                  type: 'text',
                  key: 'created',
                  label: 'Создано',
                },
                {
                  type: 'text',
                  key: 'guid',
                  label: 'GUID',
                },
                {
                  type: 'text',
                  key: 'shortName',
                  label: 'Сокращенное наименование',
                },
                {
                  type: 'text',
                  key: 'fullName',
                  label: 'Полное наименование',
                },
                {
                  type: 'text',
                  key: 'inn',
                  label: 'ИНН',
                },
                {
                  type: 'text',
                  key: 'kpp',
                  label: 'КПП',
                },
                {
                  type: 'text',
                  key: 'ogrn',
                  label: 'ОГРН',
                },
                {
                  type: 'text',
                  key: 'bankCode',
                  label: 'Код банка',
                },
                {
                  type: 'text',
                  key: 'roleName',
                  label: 'Наименование роли',
                },
                {
                  type: 'text',
                  key: 'dateOfRegestrionGisJkh',
                  label: 'Дата регистрации на ГИС ЖКХ',
                },
              ],
            },
            {
              groupTitle: 'Руководитель',
              form: [
                {
                  type: 'text',
                  key: 'chiefLastName',
                  label: 'Фамилия начальника',
                },
                {
                  type: 'text',
                  key: 'chiefFirstName',
                  label: 'Имя начальника',
                },
                {
                  type: 'text',
                  key: 'chiefMiddleName',
                  label: 'Отчество начальника',
                },
              ],
            },
            {
              groupTitle: 'Адреса',
              form: [
                {
                  type: 'text',
                  key: 'address',
                  label: 'Адрес',
                },
                {
                  type: 'text',
                  key: 'phone',
                  label: 'Телефон',
                },
                {
                  type: 'text',
                  key: 'url',
                  label: 'Сайт',
                },
                {
                  type: 'text',
                  key: 'regionShortName',
                  label: 'Короткое наименование региона',
                },
                {
                  type: 'text',
                  key: 'regionName',
                  label: 'Наименование региона',
                },
                {
                  type: 'text',
                  key: 'regionCode',
                  label: 'Код региона',
                },
                {
                  type: 'text',
                  key: 'areaName',
                  label: 'Наименование области',
                },
                {
                  type: 'text',
                  key: 'cityShortName',
                  label: 'Сокращенное наименование города',
                },
                {
                  type: 'text',
                  key: 'cityName',
                  label: 'Наименование города',
                },
                {
                  type: 'text',
                  key: 'actualAddress',
                  label: 'Фактический адрес',
                },
                {
                  type: 'text',
                  key: 'email',
                  label: 'Электронная почта',
                },
              ],
            },
            {
              groupTitle: 'Информация о помещениях',
              form: [
                {
                  type: 'number',
                  key: 'totalHouses',
                  label: 'Количество домов',
                },
                {
                  type: 'number',
                  key: 'residentialPremiseCount',
                  label: 'Количество жилых помещений',
                },
                {
                  type: 'number',
                  key: 'nonResidentialPremiseCount',
                  label: 'Количество нежилых помещений',
                },
                {
                  type: 'number',
                  key: 'residentialPremiseActualCount',
                  label: 'Количество жилых помещений фактическое значение',
                },
                {
                  type: 'number',
                  key: 'residentialPremiseConfirmedCount',
                  label: 'Подтвержденное количество жилых помещений',
                },
                {
                  type: 'number',
                  key: 'nonResidentialPremiseActualCount',
                  label: 'Количество нежилых помещений фактическое значение',
                },
                {
                  type: 'number',
                  key: 'nonResidentialPremiseConfirmedCount',
                  label: 'Подтвержденное количество нежилых помещений',
                },
                {
                  type: 'number',
                  key: 'accountCount',
                  label: 'Количество аккаунтов',
                },
                {
                  type: 'number',
                  key: 'page',
                  label: 'Количество страниц',
                },
              ],
            },
          ],
        },
      ];
    },

    dependentLinks() {
      return [
        {
          text: 'Договоры',
          route: {
            name: 'OneCContractsForOrganization',
            params: {
              id: this.form.id,
              inn: this.form.inn,
              organizationName: this.form.shortName,
            },
          },
        },

        {
          text: 'Контакты',
          route: {
            name: 'OneCContactsForOrganization',
            params: {
              id: this.form.id,
              inn: this.form.inn,
              organizationName: this.form.shortName,
            },
          },
        },
      ];
    },
  },

  created() {
    this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });
  },

  methods: {
    ...mapActions(['getItem', 'updateItem']),
  },
};
</script>
